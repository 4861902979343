export const mimetype = {
  svg: "image/svg+xml",
  png: "image/png",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  webp: "image/webp",
  zip: "application/zip",
  rar: "application/x-rar-compressed",
  "7z": "application/x-7z-compressed",
  tar: "application/x-tar",
  html: "text/html",
  htm: "text/html",
  xml: "text/xml",
  xhtml: "text/html",
  mhtml: "application/x-mimearchive",
};
